import React from "react";
import Image from "../ui/Image/Image";
import ImageGroup from "../ui/Image/ImageGroup";

const ElizabethYountDetails = () => {
	return (
		<div>
			<h1>Elizabeth Yount</h1>
			<section>
				<p>
					I had the pleasure of working with a talented Experience Designer
					looking to update her portfolio. She approached me with with the idea
					of incorporating a 90's aesthetic (sometimes referred to as{" "}
					<i>"Neo-Brutalism Web Design"</i>) while including a spotlight effect
					that followed the cursor around. This feature would add an extra layer
					of unique interactivity to the portfolio, helping it stand out among
					the competition.
				</p>

				<p>
					We collaborated closely on the design, while I took charge of the
					development. The site was built using React and the custom "Spotlight"
					effect was created from scratch with JavaScript.
				</p>

				<p>
					In the end, the client was thrilled with the results, and I was proud
					to have played a part in helping her achieve her career goals. By
					updating her portfolio with a modern and engaging design, she was able
					to stand out from the competition and take the next step in her
					career. It was a pleasure working with such a talented and motivated
					client, and I look forward to working on more projects like this in
					the future.
				</p>
				<ImageGroup>
					<Image
						src="images/projects/yount/Yount_desktop.png"
						caption="Desktop"
						alt="A screenshot of the website as it would appear on a typical desktop sized screen"
					/>
					<Image
						src="images/projects/yount/Yount_mobile.png"
						caption="Mobile"
						alt="A screenshot of the website as it would appear on a typical desktop sized screen"
					/>
				</ImageGroup>
			</section>
		</div>
	);
};

export default ElizabethYountDetails;
